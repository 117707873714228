@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.cta-block {

	@include mixins.sectionStyles;

	&.contained {

		@media only screen and (max-width: bp.$breakpoint-medium) {
			padding: 0!important;
		}

	}


	.cta-blocks {
		@include mixins.grid(400px);
		margin-top: var(--wp--preset--spacing--60);
	}
	
	.buttons, 
	.buttons .block-editor-inner-blocks > div {
		display: flex;
		justify-content: center;
		gap: 1em;
		flex-wrap: wrap;
	}

	&.dark {
		.cta-block-module {
			background-color: #353A3A;
		}
	}

}